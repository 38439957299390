<template>
    <v-card class="my-1">
        <v-card-text class="py-1">
            <div class="d-flex">
                <div class="d-flex align-start">
                    <v-avatar size="40" :color="charge.degreeColor" rounded>
                        <div class="d-flex flex-column">
                            <v-icon size="25" class="white--text"
                                >mdi-scale-balance</v-icon
                            >
                        </div>
                    </v-avatar>
                </div>
                <div class="mx-3" style="width: 100%">
                    <h3>{{ charge.detail.description }}</h3>
                    <div class="d-flex mt-3">
                        <v-chip
                            label
                            small
                            class="text-center align-center d-flex justify-center"
                            :color="charge.degreeColor"
                            style="width: 120px"
                            ><h4 class="mx-auto text-center align-center">
                                {{ charge.degree }}
                            </h4></v-chip
                        >
                        <v-chip
                            small
                            :color="charge.dispositionColor"
                            class="ml-4 pl-0 white--text"
                        >
                            <v-icon class="mr-1 white--text">{{
                                charge.dispositionIcon
                            }}</v-icon>
                            {{ charge.disposition }}</v-chip
                        >
                        <v-spacer></v-spacer>
                        <v-chip
                            small
                            class="text-uppercase grey--text text--darken-1"
                            >{{ charge.status }}</v-chip
                        >
                    </div>
                    <v-divider class="my-2" color="grey"></v-divider>
                    <div class="d-flex">
                        <span class="d-inline" style="width: 120px"
                            >Full Name:</span
                        >
                        <span class="font-weight-bold">
                            {{ charge.caseDetails.fullName }}
                        </span>
                    </div>
                    <div class="d-flex">
                        <span class="d-inline" style="width: 120px"
                            >Address:</span
                        >
                        <span class="font-weight-bold"
                            >{{ charge.caseDetails.address1 }}
                        </span>
                    </div>
                    <div class="d-flex">
                        <span class="d-inline" style="width: 120px">DOB:</span>
                        <span class="font-weight-bold"
                            >{{ charge.caseDetails.dob }}
                        </span>
                    </div>
                    <v-divider class="my-2" color="grey"></v-divider>
                    <div class="mt-2">
                        <h4 class="mb-1">Dates</h4>
                        <template
                            v-for="({ icon, date, from, label }, index) in [
                                charge.dispositionDate,
                                charge.offenseDate,
                                charge.fileDate,
                            ]"
                        >
                            <div class="d-flex" :key="index">
                                <v-icon class="mr-3">{{ icon }}</v-icon>
                                <span
                                    class="d-inline mr-3"
                                    style="width: 120px"
                                    >{{ label }}</span
                                >
                                <h4>{{ date ? date : "" }}</h4>
                                <v-spacer></v-spacer>
                                <v-chip small>{{ from }}</v-chip>
                            </div>
                            <v-divider
                                class="my-2"
                                color="grey ligthen-2"
                                :key="`divider-${index}`"
                            ></v-divider>
                        </template>
                    </div>
                    <div class="mt-2">
                        <h4 class="mb-1">Details</h4>
                        <template
                            v-for="(
                                { icon, label, text, type }, index
                            ) in charge.info"
                        >
                            <div class="d-flex" :key="index">
                                <v-icon class="mr-3">{{ icon }}</v-icon>
                                <v-chip
                                    label
                                    small
                                    class="mr-3"
                                    color="grey lighten-3"
                                    style="width: 90px"
                                    >{{ label }}</v-chip
                                >
                                <v-spacer></v-spacer>
                                <v-chip
                                    v-if="type === 'chip'"
                                    :color="charge.degreeColor"
                                    dark
                                    small
                                    class="text-uppercase font-weight-bold"
                                    >{{ text }}</v-chip
                                >
                                <span v-else class="font-weight-bold">{{
                                    text
                                }}</span>
                            </div>
                            <v-divider
                                v-if="index < charge.info.length - 1"
                                class="my-2"
                                color="grey ligthen-2"
                                :key="`divider-${index}`"
                            ></v-divider>
                        </template>
                    </div>
                </div>
            </div>
        </v-card-text>
        <v-card-actions v-if="mode === 'screen'">
            <div class="mx-3">
                <span
                    class="grey-text text--lighten-1 font-weight-light text-caption d-block"
                    >Source: {{ charge.caseDetails.source }}</span
                >
                <span
                    class="grey-text text--lighten-1 font-weight-light text-caption d-block"
                    >Import Date: {{ charge.caseDetails.importDate }}</span
                >
            </div>
            <v-spacer></v-spacer>
            <v-btn small color="secondary" class="mr-3">More Details</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "screen-charge",
    components: {},
    props: {
        charge: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            required: false,
            default: "screen",
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>