var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-1" },
    [
      _c("v-card-text", { staticClass: "py-1" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "d-flex align-start" },
            [
              _c(
                "v-avatar",
                {
                  attrs: {
                    size: "40",
                    color: _vm.charge.degreeColor,
                    rounded: ""
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { size: "25" } },
                        [_vm._v("mdi-scale-balance")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mx-3", staticStyle: { width: "100%" } },
            [
              _c("h3", [_vm._v(_vm._s(_vm.charge.detail.description))]),
              _c(
                "div",
                { staticClass: "d-flex mt-3" },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass:
                        "text-center align-center d-flex justify-center",
                      staticStyle: { width: "120px" },
                      attrs: {
                        label: "",
                        small: "",
                        color: _vm.charge.degreeColor
                      }
                    },
                    [
                      _c(
                        "h4",
                        { staticClass: "mx-auto text-center align-center" },
                        [_vm._v(" " + _vm._s(_vm.charge.degree) + " ")]
                      )
                    ]
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-4 pl-0 white--text",
                      attrs: { small: "", color: _vm.charge.dispositionColor }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1 white--text" }, [
                        _vm._v(_vm._s(_vm.charge.dispositionIcon))
                      ]),
                      _vm._v(" " + _vm._s(_vm.charge.disposition))
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-chip",
                    {
                      staticClass: "text-uppercase grey--text text--darken-1",
                      attrs: { small: "" }
                    },
                    [_vm._v(_vm._s(_vm.charge.status))]
                  )
                ],
                1
              ),
              _c("v-divider", {
                staticClass: "my-2",
                attrs: { color: "grey" }
              }),
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "span",
                  { staticClass: "d-inline", staticStyle: { width: "120px" } },
                  [_vm._v("Full Name:")]
                ),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(" " + _vm._s(_vm.charge.caseDetails.fullName) + " ")
                ])
              ]),
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "span",
                  { staticClass: "d-inline", staticStyle: { width: "120px" } },
                  [_vm._v("Address:")]
                ),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.charge.caseDetails.address1) + " ")
                ])
              ]),
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "span",
                  { staticClass: "d-inline", staticStyle: { width: "120px" } },
                  [_vm._v("DOB:")]
                ),
                _c("span", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.charge.caseDetails.dob) + " ")
                ])
              ]),
              _c("v-divider", {
                staticClass: "my-2",
                attrs: { color: "grey" }
              }),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("h4", { staticClass: "mb-1" }, [_vm._v("Dates")]),
                  _vm._l(
                    [
                      _vm.charge.dispositionDate,
                      _vm.charge.offenseDate,
                      _vm.charge.fileDate
                    ],
                    function(ref, index) {
                      var icon = ref.icon
                      var date = ref.date
                      var from = ref.from
                      var label = ref.label
                      return [
                        _c(
                          "div",
                          { key: index, staticClass: "d-flex" },
                          [
                            _c("v-icon", { staticClass: "mr-3" }, [
                              _vm._v(_vm._s(icon))
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "d-inline mr-3",
                                staticStyle: { width: "120px" }
                              },
                              [_vm._v(_vm._s(label))]
                            ),
                            _c("h4", [_vm._v(_vm._s(date ? date : ""))]),
                            _c("v-spacer"),
                            _c("v-chip", { attrs: { small: "" } }, [
                              _vm._v(_vm._s(from))
                            ])
                          ],
                          1
                        ),
                        _c("v-divider", {
                          key: "divider-" + index,
                          staticClass: "my-2",
                          attrs: { color: "grey ligthen-2" }
                        })
                      ]
                    }
                  )
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("h4", { staticClass: "mb-1" }, [_vm._v("Details")]),
                  _vm._l(_vm.charge.info, function(ref, index) {
                    var icon = ref.icon
                    var label = ref.label
                    var text = ref.text
                    var type = ref.type
                    return [
                      _c(
                        "div",
                        { key: index, staticClass: "d-flex" },
                        [
                          _c("v-icon", { staticClass: "mr-3" }, [
                            _vm._v(_vm._s(icon))
                          ]),
                          _c(
                            "v-chip",
                            {
                              staticClass: "mr-3",
                              staticStyle: { width: "90px" },
                              attrs: {
                                label: "",
                                small: "",
                                color: "grey lighten-3"
                              }
                            },
                            [_vm._v(_vm._s(label))]
                          ),
                          _c("v-spacer"),
                          type === "chip"
                            ? _c(
                                "v-chip",
                                {
                                  staticClass:
                                    "text-uppercase font-weight-bold",
                                  attrs: {
                                    color: _vm.charge.degreeColor,
                                    dark: "",
                                    small: ""
                                  }
                                },
                                [_vm._v(_vm._s(text))]
                              )
                            : _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(text))
                              ])
                        ],
                        1
                      ),
                      index < _vm.charge.info.length - 1
                        ? _c("v-divider", {
                            key: "divider-" + index,
                            staticClass: "my-2",
                            attrs: { color: "grey ligthen-2" }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ])
      ]),
      _vm.mode === "screen"
        ? _c(
            "v-card-actions",
            [
              _c("div", { staticClass: "mx-3" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "grey-text text--lighten-1 font-weight-light text-caption d-block"
                  },
                  [_vm._v("Source: " + _vm._s(_vm.charge.caseDetails.source))]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "grey-text text--lighten-1 font-weight-light text-caption d-block"
                  },
                  [
                    _vm._v(
                      "Import Date: " +
                        _vm._s(_vm.charge.caseDetails.importDate)
                    )
                  ]
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", color: "secondary" }
                },
                [_vm._v("More Details")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }